import React from 'react';
import Navbar from '../Components/Navbar';
import Sidebar from '../Components/Sidebar';

const Layout = ({ children }) => {
    return (
        <div className="flex h-screen w-[100%]">
            <Sidebar />
            <div className="flex flex-col flex-grow w-[100%]">
                <Navbar />
                <div className="p-4 flex-grow overflow-auto scrollbar-hide whitespace-nowrap w-[100%]">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Layout;
