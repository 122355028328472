import logo from './logo.svg';
import './App.css';
import {Routes,Route} from 'react-router-dom'
import Home from './Pages/Home';
import DeactivationLogs from './Pages/DeactivationLogs';


function App() {
  return (
   <Routes>
   <Route path='/' element={<Home/>}/>
   <Route path='/deactivation-logs' element={<DeactivationLogs/>}/>
   </Routes>
  );
}

export default App;
